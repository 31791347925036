import './Gallery.css';
import React, { useEffect, useRef} from 'react'
import gsap from 'gsap';

import slide1 from '../../../assets/images/xc40-slide-1.jpg'
import slide2 from '../../../assets/images/xc40-slide-2.jpg'
import slide3 from '../../../assets/images/xc40-slide-3.jpg'
import arrowLeft from '../../../assets/images/arrow-left.svg'
import arrowRight from '../../../assets/images/arrow-right.svg'

import { useParams } from "react-router-dom";


function Gallery() {
    let { id } = useParams();
    if(!id){id="";}
    
    useEffect(() => { 

    },[]);
    
    const slides = [useRef(),useRef(),useRef()];

    
    const nextArrowClicked=(e)=>{
        let pos = document.querySelector('.gallery-slides').scrollLeft;
        let eventCategory = 'Smart storage';

        switch (1 + Math.floor(pos/279)){
            case 1:
                eventCategory = 'Smart storage';
                break;
            case 2:
                eventCategory = 'Sustainable materials';
                break;
            case 3:
                eventCategory = 'Google built-in';
                break;
            default:
                eventCategory = 'Create your perfect space';
                break;
        }

        window.dataLayer.push({
            event: 'click',
            pageType: 'online sales event',
            pageName:	'c40 landing page',
            eventCategory: eventCategory,        
            eventLabel: 'right arrow',
            carModelName:'C40 Recahrge',
            carModelYear:'2021',
        });

        let sl = document.querySelector('.gallery-slides').scrollLeft;
        if(window.innerWidth > 420 ){
            gsap.to('.gallery-slides',{ scrollLeft:sl + 200 })
        } else {
            gsap.to('.gallery-slides',{ scrollLeft:sl + (window.innerWidth/100) * (279/3) })
        }
        
    }

    const backArrowClicked=(e)=>{
        let pos = document.querySelector('.gallery-slides').scrollLeft;
        let eventCategory = 'Smart storage';

        switch (1 + Math.floor(pos/279)){
            case 1:
                eventCategory = 'Smart storage';
                break;
            case 2:
                eventCategory = 'Sustainable materials';
                break;
            case 3:
                eventCategory = 'Google built-in';
                break;
            default:
                eventCategory = 'Create your perfect space';
                break;
        }

        window.dataLayer.push({
            event: 'click',
            pageType: 'online sales event',
            pageName:	'c40 landing page',
            eventCategory: eventCategory,        
            eventLabel: 'left arrow',
            carModelName:'C40 Recahrge',
            carModelYear:'2021',
        });

        let sl = document.querySelector('.gallery-slides').scrollLeft;
        if(window.innerWidth > 420 ){
            gsap.to('.gallery-slides',{ scrollLeft:sl - 200 })
        } else {
            gsap.to('.gallery-slides',{ scrollLeft:sl - (window.innerWidth/100) * (279/3) })
        }
    }

    const learnMoreClicked = (_id )=>{
        
        let eventCategory = 'The future is now';

        switch(_id) {
            case 1:
                eventCategory = 'Smart storage';
                break;
            case 2:
                eventCategory = 'Sustainable materials';
                break;
            case 3:
                eventCategory = 'Google built-in';
                break;
            default:
                eventCategory = 'Create your perfect space';
                break;
        }

        window.dataLayer.push({
            event: 'click',
            pageType: 'online sales event',
            pageName:	'c40 landing page',
            eventCategory: eventCategory,        
            eventLabel: 'learn more',
            carModelName:'C40 Recahrge',
            carModelYear:'2021',
        });

        window.open('https://www.volvocars.com/us/v/cars/xc40-electric?utm_source=akqa&utm_medium=referral&utm_campaign=' + id);
    }
    
    return (
        <div className={`section gallery`}>
            
            <div className="gallery-slides">
                <div className="gallery-slides-container">
                    <img ref={ slides[0] } className={"gallery-slide slide-1"} src={slide1} alt="Smart Storage" />
                    <img ref={ slides[1] } className={"gallery-slide slide-2"} src={slide2} alt="Sustainable materials" />
                    <img ref={ slides[2] } className={"gallery-slide slide-3"} src={slide3} alt="Create your perfect space" />
                </div>
            </div>
            <div className="gallery-nav">
                <img id="btnGalleryLeft" onClick={backArrowClicked} src={arrowLeft} className={"gallery-arrow arrow-left"} alt="navigate left" />
                <img id="btnGalleryRight" onClick={nextArrowClicked} src={arrowRight} className={"gallery-arrow arrow-right"}alt="navigate right" />
            </div>
            <div className={`title-block title-block-1`}>
                <h2 className="title">Smart storage</h2>
                <div className="sub-title">Inside, you’ll discover smart storage solutions for water bottles, gym bags, phones, laptops, take-away food, and much more, as well as a front compartment for your charging cables.</div>
                <button id="btnLearnMore1" onClick={() => learnMoreClicked(1)} className="learn-button">Learn More</button>
            </div>
            <div className={`title-block title-block-2`}>
                <h2 className="title">Sustainable materials</h2>
                <div className="sub-title">Increasing the use of sustainable materials is just one way the XC40 Recharge helps reduce its environmental impact. For example, the interior carpeting is made entirely from recycled plastic.</div>
                <button id="btnLearnMore2" onClick={() => learnMoreClicked(2)} className="learn-button">Learn More</button>
            </div>
            <div className={`title-block title-block-3`}>
                <h2 className="title">Create your perfect space</h2>
                <div className="sub-title">Pre-heat, pre-cool, and more — with a simple command, the Volvo Cars app lets you create your own perfect space remotely before you set off.</div>
                <button id="btnLearnMore3" onClick={() => learnMoreClicked(3)} className="learn-button">Learn More</button>
            </div>
            
        </div>
    )
}

export default Gallery;