import './Video.css';
import React, { useEffect, useState} from 'react'
import ReactPlayer from 'react-player'
import video from '../../../assets/videos/AKQA_Volvo Expo_XC40 Vertical_R1.1_072921_1.mp4'
import playButton from '../../../assets/images/play-button.svg'
import pauseButton from '../../../assets/images/pause-button.svg'
import { useParams } from "react-router-dom";

function Video() {
    let { id } = useParams();
    if(!id){id="";}
    
    useEffect(() => { 

    },[]);
    
    const [paused,setPaused] = useState(false);

    const togglePlay = () => {
        //alert(paused)
        return setPaused( !paused );
    }

    const orderClicked = (e)=>{
        window.dataLayer.push({
            event: 'click',
            pageType: 'online sales event',
            pageName:	'xc40 landing page',
            eventCategory: 'steps',  
            eventLabel: 'order',
            carModelName:'XC40 Recahrge',
            carModelYear:'2021',
        });

        window.open("https://www.volvocars.com/us/shop/?utm_source=akqa&utm_medium=referral&utm_campaign=" + id);
    }

    return (
        <div className={`section video`}>
            <ReactPlayer 
                    className='react-player'
                    url={video} 
                    playing={!paused} 
                    width='100%'
                    height='100%'
                    muted={true}
                    loop={true}
                    playsinline={true}
                    aria-hidden={true}
                />
                <img id="btnToggleOrderVideo" onClick={togglePlay} className="toggle-play-button" src={ paused ? playButton : pauseButton } alt="play pause button" />
            <div className='player-overlay'></div>
            <button id="btnOrderInnerOverVideo" onClick={orderClicked} className="interior-order-button">ORDER</button>

        </div>
    )
}

export default Video;