import './Landing.css';
import React, { useEffect} from 'react'
import { useParams } from "react-router-dom";
import LandingBackground from '../../../assets/images/xc40-background.jpg'

function Landing() {
    let { id } = useParams();
    if(!id){id="";}
    
    useEffect(() => { 

    },[]);
    

    const subscribeClicked = (e)=>{
        window.dataLayer.push({
            event: 'click',
            pageType: 'online sales event',
            pageName:	'xc40 landing page',
            eventCategory: 'Charge with a plug. Shop with a click.',  
            eventLabel: 'subscribe',
            carModelName:'XC40 Recahrge',
            carModelYear:'2021',
        });

        window.open('https://www.volvocars.com/us/care-by-volvo/?utm_source=akqa&utm_medium=referral&utm_campaign=' + id);
    }

    const orderClicked = (e)=>{

        window.dataLayer.push({
            event: 'click',
            pageType: 'online sales event',
            pageName:	'xc40 landing page',
            eventCategory: 'Charge with a plug. Shop with a click.',  
            eventLabel: 'order',
            carModelName:'XC40 Recahrge',
            carModelYear:'2021',
        });

        window.open('https://www.volvocars.com/us/shop/?utm_source=akqa&utm_medium=referral&utm_campaign=' + id);
    }

    return (
        <div className={`section landing`}>
            <img src={LandingBackground} className="landing-background"  alt="XC40 Charging" aria-hidden="true" />
            <div className="product-title">XC40 RECHARGE</div>
            <div className="title-block">
                <h1 className="title">Charge with a plug.<br />Shop with a click.</h1>
                <div className="sub-title">A new way to drive means a modern way<br />to order or lease your car online.</div>
                <button id="btnOrderOntro" onClick={orderClicked} className="order-button">ORDER</button>
                <button id="btnSubscribeIntro" onClick={subscribeClicked} className="subscribe-button">SUBSCRIBE</button>
                <span className="legal-link">Not available for CA or NY residents.</span>
            </div>
        </div>
    )
}

export default Landing;