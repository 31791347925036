import React, {useEffect} from "react";

import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger); 

const Animation = ()=> {
  
  const trackSection = (section)=>{
    window.dataLayer.push({
      event: 'scroll',
      section: section,
      pageType: 'online sales event',
      pageName:	'xc40 landing page',
      eventCategory: section,  
      eventLabel: 'scroll',
      carModelName:'XC40 Recahrge',
      carModelYear:'2021',
    });
    //console.log('track',section);
  }
  

  useEffect(() => {
      
      const tl = gsap.timeline({paused:false});
      tl.set('.video .player-overlay',{opacity:1});
      tl.set('.subscribe-player-overlay',{opacity:1});
      tl.set('.header-subscribe-button',{opacity:0},0);

      tl.to('.scroll-arrow',{autoAlpha:0,y:30, duration:.2},.1);
      tl.fromTo(['.landing'],{yPercent:0},{ yPercent:-100, duration:1,ease:"none"},0);
      
      tl.fromTo(['.steps'],{yPercent:100},{ yPercent:-300, duration:3,ease:"none"},.7);
      tl.fromTo(['.steps .step1'],{opacity:0},{ opacity:1, duration:.1},1.25);
      tl.fromTo(['.steps .step2'],{opacity:0},{ opacity:1, duration:.1},2.05);
      tl.fromTo(['.steps .step3'],{opacity:0},{ opacity:1, duration:.1},2.75);

      tl.fromTo(['.steps .step1'],{opacity:1},{ opacity:0, duration:.1},1.5);
      tl.fromTo(['.steps .step2'],{opacity:1},{ opacity:0, duration:.1},2.3);
      tl.fromTo(['.steps .step3'],{opacity:1},{ opacity:0, duration:.1},3.);

      tl.to('.video .player-overlay',{opacity:.4,duration:".4"},.3);
      
      tl.fromTo(['.video'],{yPercent:0},{ yPercent:-100, duration:1.3/2,ease:"none"},3.2);
      tl.fromTo(['.subscribe-landing'],{yPercent:100},{ yPercent:-100, duration:1.3,ease:"none"},3.2);
      
      tl.fromTo(['.subscribe-video'],{yPercent:100},{ yPercent:0, duration:1.3/2,ease:"none"},3.2);
      

      tl.fromTo(['.subscribe-steps'],{yPercent:100},{ yPercent:-400, duration:4,ease:"none"},3.9);
      tl.fromTo(['.subscribe-steps .step1'],{opacity:0},{ opacity:1, duration:.1},4.45);
      tl.fromTo(['.subscribe-steps .step2'],{opacity:0},{ opacity:1, duration:.1},5.25);
      tl.fromTo(['.subscribe-steps .step3'],{opacity:0},{ opacity:1, duration:.1},6.05);
      tl.fromTo(['.subscribe-steps .step4'],{opacity:0},{ opacity:1, duration:.1},6.85);

      tl.fromTo(['.subscribe-steps .step1'],{opacity:1},{ opacity:0, duration:.1},4.8);
      tl.fromTo(['.subscribe-steps .step2'],{opacity:1},{ opacity:0, duration:.1},5.6);
      tl.fromTo(['.subscribe-steps .step3'],{opacity:1},{ opacity:0, duration:.1},6.4);
      tl.fromTo(['.subscribe-steps .step4'],{opacity:1},{ opacity:0, duration:.1},7.2);

      tl.to('.subscribe-video .subscribe-player-overlay',{opacity:.4,duration:".4"},4.0);
      
      tl.fromTo(['.gallery'],{yPercent:100},{ yPercent:-30, duration:1.3,ease:"none"},7.4);
      tl.fromTo(['.subscribe-video'],{yPercent:0},{ yPercent:-100, duration:1,ease:"none"},7.4);
      tl.fromTo(['.footer'],{yPercent:333.33} ,{ yPercent:250, duration:.3,ease:"none"},8.41);
      
      tl.set('.alt-header',{opacity:0},0);
      tl.set('.header-subscribe-button',{opacity:1},3.8);
      tl.to('.alt-header',{opacity:1,duration:.05},3.8);
      tl.to('.alt-header',{opacity:0,duration:.05},4.45);
      tl.set('.header-subscribe-button',{opacity:0},5);
      tl.to('.alt-header',{opacity:1,duration:.05},8.35);

      // GTM tracking for scroll position
      tl.call(trackSection,['Step 01 - Design'],1.26);
      tl.call(trackSection,['Step 02 - Finance'],2.06);
      tl.call(trackSection,['Step 03 - Reserve'],2.76);
      tl.call(trackSection,['Care By Volvo'],3.7);
      tl.call(trackSection,['Step 01 - Select'],4.46);
      tl.call(trackSection,['Step 02 - Start'],5.26);
      tl.call(trackSection,['Step 03 - Delivery'],6.06);
      tl.call(trackSection,['Step 04 - Drive'],6.86);
      tl.call(trackSection,['Gallery'],8.2);

      ScrollTrigger.create({
        animation: tl,
        trigger:'.xc40',
        start:"top top",
        end:'+=6000',
        scrub:.1,
        pin: true,

      })
      
      // gallery triggers
      const gtl = gsap.timeline({paused:false});
      gtl.set(['.title-block-1'],{autoAlpha:1});
      gtl.to(['.title-block-1'],{ autoAlpha:0, duration:1});
      gtl.to(['.title-block-2'],{ autoAlpha:1, duration:1});
      gtl.to(['.title-block-2'],{ autoAlpha:0, duration:1});
      gtl.to(['.title-block-3'],{ autoAlpha:1, duration:1});
     
      //gtl.play();

      ScrollTrigger.create({
        animation: gtl,
        scroller:'.gallery-slides',
        horizontal: true,
        trigger:'.gallery-slides-container',
        start:'left left',
        end:'right right',
        scrub:true,
      })



    return() =>{
      
    }
  }, [])

  
  return(
    <></>
  )
};
  
export default Animation;