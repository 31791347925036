import './XC40.css';
import React, { useEffect} from 'react'
import { useParams } from 'react-router-dom';
import VolvoWordmark from  '../../assets/images/volvo-wordmark.svg'
import ScrollArrow from  '../../assets/images/scroll-arrow.svg'
import Landing from './Landing/Landing'
import Steps from './Steps/Steps'
import Video from './Video/Video';
import Gallery from './Gallery/Gallery';
import Footer from './Footer/Footer';

import Animation from './Animation';
import SubscribeLanding from './SubscribeLanding/SubscribeLanding';
import SubscribeVideo from './SubscribeVideo/SubscribeVideo';
import SubscribeSteps from './SubscribeSteps/SubscribeSteps';

function XC40() {
    
    let { id } = useParams();
    if(!id){id="";}

    if(id){document.title = "Volvo Cars Event - XC40 - "+id}

    useEffect(() => { 
        window.dataLayer.push({
            event: 'pageview',
            pageType: 'online sales event',
            pageName:	'xc40 landing page'
          });
      
    },[]);

    const subscribeClicked = (e)=>{
        window.dataLayer.push({
            event: 'click',
            pageType: 'online sales event',
            pageName:	'xc40 landing page',
            eventCategory: 'care by volvo',  
            eventLabel: 'subscribe',
            carModelName:'XC40 Recahrge',
            carModelYear:'2021',
        });
        
        window.open('https://www.volvocars.com/us/care-by-volvo/?utm_source=akqa&utm_medium=referral&utm_campaign=' + id);
    }
    
    return (
        <div className="xc40">
            <Animation />
            <Landing />
            <Video />
            <Steps />
            <SubscribeLanding />
            <SubscribeVideo />
            <SubscribeSteps />
            <Gallery />
            <Footer />
            <img src={VolvoWordmark} className="volvo-wordmark" alt="Volvo Wordmark" aria-label="Volvo Wordmark" />
            <img src={ScrollArrow} className="scroll-arrow" alt="scroll down arrow" aria-label="scroll down arrow" />
            <div className="alt-header">
                <img src={VolvoWordmark} className="alt-volvo-wordmark" alt="Volvo Wordmark" aria-label="Volvo Wordmark" />
                <button id="btnSubscribeInnerOverHeader" onClick={subscribeClicked} className="header-subscribe-button">SUBSCRIBE</button>
            </div>
        </div>
    )
}

export default XC40;