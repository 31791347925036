import './Video.css';
import React, { useEffect, useState} from 'react'
import { useParams } from "react-router-dom";
import ReactPlayer from 'react-player'
import video from '../../../assets/videos/AKQA_Volvo Expo_C40 Vertical_R1.3_072921.mp4'
import playButton from '../../../assets/images/play-button.svg'
import pauseButton from '../../../assets/images/pause-button.svg'

function Video() {
    let { id } = useParams();
    if(!id){id="";}
    
    useEffect(() => { 

    },[]);
    
    const [paused,setPaused] = useState(false);

    const togglePlay = () => {
        window.dataLayer.push({
            event: 'click',
            pageType: 'online sales event',
            pageName:	'c40 landing page',     
            eventLabel: 'pause video',
        });
        return setPaused( !paused );
    }

    const reserveClicked = (e)=>{
        window.dataLayer.push({
            event: 'click',
            pageType: 'online sales event',
            pageName:	'c40 landing page',
            eventCategory: 'steps',        
            eventLabel: 'reserve yours',
            carModelName:'C40 Recahrge',
            carModelYear:'2021',
        });

        window.open('https://www.volvocars.com/us/build/c40-electric?utm_source=akqa&utm_medium=referral&utm_campaign=' + id);
    }

    return (
        <div className={`section video`}>
            <ReactPlayer 
                    className='react-player'
                    url={video} 
                    playing={!paused} 
                    width='100%'
                    height='100%'
                    muted={true}
                    loop={true}
                    playsinline={true}
                    aria-hidden={true}
                />
                <img id="btnTogglePlay" onClick={togglePlay} className="toggle-play-button" src={ paused ? playButton : pauseButton } alt="play pause button" />
            <div className='player-overlay'></div>
            <button id="btnReserveInnner" onClick={reserveClicked} className="interior-reserve-button">RESERVE YOURS</button>

        </div>
    )
}

export default Video;