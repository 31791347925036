import './SubscribeLanding.css';
import React, { useEffect} from 'react'
import { useParams } from 'react-router-dom';
import LandingBackground from '../../../assets/images/xc40-subscribe-background.jpg'

function SubscribeLanding() {
    let { id } = useParams();
    if(!id){id="";}
    
    useEffect(() => { 

    },[]);
    
    const subscribeClicked = (e)=>{
        window.open('https://www.volvocars.com/us/care-by-volvo/?utm_source=akqa&utm_medium=referral&utm_campaign=' + id);
    }

    return (
        <div className={`section subscribe-landing`}>
            <img src={LandingBackground} className="subscribe-landing-background"  alt="XC40 Care By Volvo" aria-hidden="true" />
            <div className="subscribe-event-title">CARE BY VOLVO</div>
            <div className="subscribe-product-title"></div>
            <div className="subscribe-title-block">
                <h2 className="subscribe-title">The all-inclusive<br />car subscription.</h2>
                <div className="subscribe-sub-title">A modern way to lease the XC40<br />Recharge. Subscribe online.<br />No long-term commitment.</div>
                <button id="btnSubscribeInner" onClick={subscribeClicked} className="subscribe-subscribe-button">SUBSCRIBE</button>
            </div>
            <div className="subscribe-footer">Care by Volvo is not available to CA residents. If you are a NY resident, please contact your local retailer.</div>
        </div>
    )
}

export default SubscribeLanding;