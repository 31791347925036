import './C40.css';
import React, { useEffect} from 'react'
import { useParams } from 'react-router-dom';
import VolvoWordmark from  '../../assets/images/volvo-wordmark.svg'
import ScrollArrow from  '../../assets/images/scroll-arrow.svg'
import Landing from './Landing/Landing'
import Steps from './Steps/Steps'
import Video from './Video/Video';
import Gallery from './Gallery/Gallery';
import Footer from './Footer/Footer';

import Animation from './Animation';

function C40() {
    
    let { id } = useParams();
    if(!id){id="";}

    if(id){document.title = "Volvo Cars Event - C40 - "+id}

    useEffect(() => { 
        window.dataLayer.push({
            event: 'pageview',
            pageType: 'online sales event',
            pageName:	'c40 landing page'
        });
    },[]);
    
    return (
        <div className="c40">
            <Animation />
            <Landing />
            <Video />
            <Steps />
            <Gallery />
            <Footer />
            <img src={VolvoWordmark} className="volvo-wordmark" alt="Volvo Wordmark" aria-label="Volvo Wordmark" />
            <img src={ScrollArrow} className="scroll-arrow" alt="scroll down arrow" aria-label="scroll down arrow" />
            <div className="alt-header">
                <img src={VolvoWordmark} className="alt-volvo-wordmark" alt="Volvo Wordmark" aria-label="Volvo Wordmark" />
            </div>
        </div>
    )
}

export default C40;