import React, {useEffect} from "react";

import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger); 

const Animation = ()=> {
  
  const trackSection = (section)=>{
    window.dataLayer.push({
      event: 'scroll',
      section: section,
      pageType: 'online sales event',
      pageName:	'c40 landing page',
      eventCategory: section,  
      eventLabel: 'scroll',
      carModelName:'C40 Recahrge',
      carModelYear:'2021',
    });
    //console.log('track',section);
  }
  
  
  useEffect(() => {
      
      const tl = gsap.timeline({paused:false});
      //tl.set(['.section'],{ yPercent:100},0);
      tl.set('.video .player-overlay',{opacity:1});
      tl.to('.scroll-arrow',{autoAlpha:0,y:30, duration:.2},.1);
      tl.fromTo(['.landing'],{yPercent:0},{ yPercent:-100, duration:1,ease:"none"},0);
      
      tl.fromTo(['.steps'],{yPercent:100},{ yPercent:-300, duration:3,ease:"none"},.7);
      tl.fromTo(['.step1'],{opacity:0},{ opacity:1, duration:.1},1.25);
      tl.fromTo(['.step2'],{opacity:0},{ opacity:1, duration:.1},2.05);
      tl.fromTo(['.step3'],{opacity:0},{ opacity:1, duration:.1},2.75);

      tl.fromTo(['.step1'],{opacity:1},{ opacity:0, duration:.1},1.5);
      tl.fromTo(['.step2'],{opacity:1},{ opacity:0, duration:.1},2.3);
      tl.fromTo(['.step3'],{opacity:1},{ opacity:0, duration:.1},3.0);

      tl.fromTo(['.gallery'],{yPercent:100},{ yPercent:-30, duration:1.3,ease:"none"},3.2);
      tl.fromTo(['.video'],{yPercent:0},{ yPercent:-100, duration:1,ease:"none"},3.2);
      tl.fromTo(['.footer'],{yPercent:333.33} ,{ yPercent:250, duration:.3,ease:"none"},4.21);
      
      tl.to('.video .player-overlay',{opacity:.4,duration:".3"},.3);
      
      // anaimte new header 
      tl.set('.alt-header',{opacity:0},0);
      tl.to('.alt-header',{opacity:1,duration:.05},4.15);

      // GTM tracking for scroll position
      tl.call(trackSection,['Step 01 - Design'],1.26);
      tl.call(trackSection,['Step 02 - Reserve'],2.06);
      tl.call(trackSection,['Step 03 - Finalize'],2.76);
      tl.call(trackSection,['Gallery'],3.9);


      ScrollTrigger.create({
        animation: tl,
        trigger:'.c40',
        start:"top top",
        end:'+=6000',
        scrub:.1,
        pin: true,
        //invalidateOnRefresh: true,
        //anticipatePin:1,
      })
      
      // gallery triggers
      const gtl = gsap.timeline({paused:false});
      //tl.set(['.section'],{ yPercent:100},0);
      gtl.set(['.title-block-1'],{autoAlpha:1});
      gtl.to(['.title-block-1'],{ autoAlpha:0, duration:1});
      gtl.to(['.title-block-2'],{ autoAlpha:1, duration:1});
      gtl.to(['.title-block-2'],{ autoAlpha:0, duration:1});
      gtl.to(['.title-block-3'],{ autoAlpha:1, duration:1});
     
      //gtl.play();

      ScrollTrigger.create({
        animation: gtl,
        scroller:'.gallery-slides',
        horizontal: true,
        trigger:'.gallery-slides-container',
        start:'left left',
        end:'right right',
        //endTrigger:'.gallery .slide-3',
        //end:'+=300%',
        scrub:true,
        //markers:true,
        onUpdate: (e)=>{
          //console.log(e);
        }
      })
    

    return() =>{
      
    }
  }, [])

  
 

  /*
  const videoScrollTrigger = gsap.to(".video", {
    scrollTrigger: {
      trigger: ".landing",
      start:"top top",
      endTrigger:".gallery",
      end:"top bottom",
      //scrub:"true",

      markers:"true",

    },
    y:"-100%",
    ease:"none",
  });
  */


  return(
    <></>
  )
};
  
export default Animation;