import './SubscribeVideo.css';
import React, { useEffect, useState} from 'react'
import { useParams } from 'react-router-dom';
import ReactPlayer from 'react-player'
import video from '../../../assets/videos/AKQA_Volvo Expo_XC40 Vertical_2_R1.1_080421.mp4'
import playButton from '../../../assets/images/play-button.svg'
import pauseButton from '../../../assets/images/pause-button.svg'

function SubscribeVideo() {
    let { id } = useParams();
    if(!id){id="";}
    
    useEffect(() => { 

    },[]);
    
    const [paused,setPaused] = useState(false);

    const togglePlay = () => {
        window.dataLayer.push({
            event: 'click',
            pageType: 'online sales event',
            pageName:	'xc40 landing page',
            eventCategory: 'video',  
            eventLabel: 'pause video',
            carModelName:'XC40 Recahrge',
            carModelYear:'2021',
        });
        return setPaused( !paused );
    }

    const subscribeClicked = (e)=>{
        window.dataLayer.push({
            event: 'click',
            pageType: 'online sales event',
            pageName:	'xc40 landing page',
            eventCategory: 'steps',  
            eventLabel: 'subscribe',
            carModelName:'XC40 Recahrge',
            carModelYear:'2021',
        });

        window.open('https://www.volvocars.com/us/care-by-volvo/?utm_source=akqa&utm_medium=referral&utm_campaign=' + id);
    }

    return (
        <div className={`section subscribe-video`}>
            <ReactPlayer 
                    className='react-player'
                    url={video} 
                    playing={!paused} 
                    width='100%'
                    height='100%'
                    muted={true}
                    loop={true}
                    playsinline={true}
                    aria-hidden={true}
                />
                <img id="btnToggleSubscribeVideo" onClick={togglePlay} className="subscribe-toggle-play-button" src={ paused ? playButton : pauseButton } alt="play pause button" />
            <div className='subscribe-player-overlay'></div>
            <button id="btnSubscribeInnerOverVideo" onClick={subscribeClicked} className="interior-subscribe-button">SUBSCRIBE</button>

        </div>
    )
}

export default SubscribeVideo;