import './Footer.css';
import React, { useEffect} from 'react'
import { useParams } from "react-router-dom";


function Footer() {
    let { id } = useParams();
    if(!id){id="";}
    
    useEffect(() => { 

    },[]);
    
    return (
        <div className={`section footer`}>  
            <div className="footer-links">
                <a target="_blank" id="linkCookies" rel="noopener noreferrer" href={"https://www.volvocars.com/us/v/legal/cookies?utm_source=akqa&utm_medium=referral&utm_campaign=" + id} >Cookies</a>
                <a target="_blank" id="linkLegal"  rel="noopener noreferrer" href={"https://www.volvocars.com/us/legal-policies/legal/user-agreement-and-copyright-notice/?utm_source=akqa&utm_medium=referral&utm_campaign="+ id} >Legal</a>
                <a target="_blank" id="linkPrivacy" rel="noopener noreferrer" href={"https://www.volvocars.com/us/legal-policies/privacy/general-privacy-statement/?utm_source=akqa&utm_medium=referral&utm_campaign="+ id} >Privacy</a>
                <a target="_blank" id="linkAccessibility" rel="noopener noreferrer" href={"https://www.volvocars.com/us/legal-policies/accessibility/ada-compliance-statement/?utm_source=akqa&utm_medium=referral&utm_campaign="+ id}  >Accessibility</a>
                <a target="_blank" id="linkPrivacy" rel="noopener noreferrer" href={"https://www.volvocars.com/us/form/california-consumer-privacy-act?utm_source=akqa&utm_medium=referral&utm_campaign="+ id} >Do Not Sell My Personal Information</a>
            </div>
            <div className="copyright">Copyright © 2021 Volvo Car Corporation (or its affiliates or licensors)</div>
        </div>
    )
}

export default Footer;