import './Landing.css';
import React, { useEffect} from 'react'
import { useParams } from "react-router-dom";
import LandingBackground from '../../../assets/images/c40-background.jpg'

function Landing() {
    let { id } = useParams();
    if(!id){id="";}
    
    useEffect(() => { 

    },[]);
    
    const reserveClicked = (e)=>{
        window.dataLayer.push({
            event: 'click',
            pageType: 'online sales event',
            pageName:	'c40 landing page',
            eventCategory: 'the future of electric vehicles',        
            eventLabel: 'reserve yours',
            carModelName:'C40 Recharge',
            carModelYear:'2021',
        });

        window.open('https://www.volvocars.com/us/build/c40-electric?utm_source=akqa&utm_medium=referral&utm_campaign=' + id);
    }

    return (
        <div className={`section landing`}>
            <img src={LandingBackground} className="landing-background"  alt="C40 Charging" aria-hidden="true" />
            <div className="product-title">C40 RECHARGE</div>
            <div className="title-block">
                <h1 className="title">The future of<br />electric vehicles.</h1>
                <div className="sub-title">A new way to drive deserves a new<br />way to order.</div>
                <button id="btnReserve" onClick={reserveClicked} className="reserve-button">RESERVE YOURS</button>
                <span className="legal-link-c40">Not available for CA or NY residents.</span>
            </div>
        </div>
    )
}

export default Landing;