import './SubscribeSteps.css';
import React, { useEffect} from 'react'

function SubscribeSteps() {
    //let { id } = useParams();
    
    useEffect(() => { 

    },[]);
    

    return (
        <div className={`section subscribe-steps`}>
            <div className={`step step1`}>
                <div className="step-block">
                    <h2 className="step-number">Step 01</h2>
                    <h3 className="step-title">Select</h3>
                    <div className="step-content">Select your Volvo XC40 Recharge.</div>
                </div>
            </div>
            <div className={`step step2`}>
                <div className="step-block">
                    <h2 className="step-number">Step 02</h2>
                    <h3 className="step-title">Start</h3>
                    <div className="step-content">In a few easy steps, we confirm your insurance and credit eligibility.</div>
                </div>
            </div>
            <div className={`step step3`}>
                <div className="step-block">
                    <h2 className="step-number">Step 03</h2>
                    <h3 className="step-title">Delivery</h3>
                    <div className="step-content">Your vehicle will be available for pickup within an estimated 2 weeks of order confirmation.</div>
                </div>
            </div>
            <div className={`step step4`}>
                <div className="step-block">
                    <h2 className="step-number">Step 04</h2>
                    <h3 className="step-title">Drive</h3>
                    <div className="step-content">Our flexible subscription term allows you to make the right decision, guaranteed.</div>
                </div>
            </div>
        </div>
    )
}

export default SubscribeSteps;