import './Gallery.css';
import React, { useEffect, useRef} from 'react'
import gsap from 'gsap';

import slide1 from '../../../assets/images/slide-1.png'
import slide2 from '../../../assets/images/slide-2.png'
import slide3 from '../../../assets/images/slide-3.png'
import arrowLeft from '../../../assets/images/arrow-left.svg'
import arrowRight from '../../../assets/images/arrow-right.svg'

import { useParams } from "react-router-dom";

function Gallery() {
    let { id } = useParams();
    if(!id){id="";}
    
    useEffect(() => { 

    },[]);
    
    const slides = [useRef(),useRef(),useRef()];

    
    const nextArrowClicked=(e)=>{
        let pos = document.querySelector('.gallery-slides').scrollLeft;
        let eventCategory = 'The future is now';

        switch (1 + Math.floor(pos/279)){
            case 1:
                eventCategory = 'The future is now';
                break;
            case 2:
                eventCategory = 'Feel the change';
                break;
            case 3:
                eventCategory = 'Google built-in';
                break;
            default:
                eventCategory = 'The future is now';
                break;
        }

        window.dataLayer.push({
            event: 'click',
            pageType: 'online sales event',
            pageName:	'c40 landing page',
            eventCategory: eventCategory,        
            eventLabel: 'right arrow',
            carModelName:'C40 Recahrge',
            carModelYear:'2021',
        });

        let sl = document.querySelector('.gallery-slides').scrollLeft;
        if(window.innerWidth > 420 ){
            gsap.to('.gallery-slides',{ scrollLeft:sl + 200 })
        } else {
            gsap.to('.gallery-slides',{ scrollLeft:sl + (window.innerWidth/100) * (279/3) })
        }
        
    }

    const backArrowClicked=(e)=>{

        let pos = document.querySelector('.gallery-slides').scrollLeft;
        let eventCategory = 'The future is now';

        switch (1 + Math.floor(pos/279)){
            case 1:
                eventCategory = 'The future is now';
                break;
            case 2:
                eventCategory = 'Feel the change';
                break;
            case 3:
                eventCategory = 'Google built-in';
                break;
            default:
                eventCategory = 'The future is now';
                break;
        }

        window.dataLayer.push({
            event: 'click',
            pageType: 'online sales event',
            pageName:	'c40 landing page',
            eventCategory: eventCategory,        
            eventLabel: 'left arrow',
            carModelName:'C40 Recahrge',
            carModelYear:'2021',
        });

        let sl = document.querySelector('.gallery-slides').scrollLeft;
        if(window.innerWidth > 420 ){
            gsap.to('.gallery-slides',{ scrollLeft:sl - 200 })
        } else {
            gsap.to('.gallery-slides',{ scrollLeft:sl - (window.innerWidth/100) * (279/3) })
        }
    }

    const learnMoreClicked = (_id )=>{
        
        let eventCategory = 'The future is now';

        switch(_id) {
            case 1:
                eventCategory = 'The future is now';
                break;
            case 2:
                eventCategory = 'Feel the change';
                break;
            case 3:
                eventCategory = 'Google built-in';
                break;
            default:
                eventCategory = 'The future is now';
                break;
        }

        window.dataLayer.push({
            event: 'click',
            pageType: 'online sales event',
            pageName:	'c40 landing page',
            eventCategory: eventCategory,        
            eventLabel: 'learn more',
            carModelName:'C40 Recahrge',
            carModelYear:'2021',
        });

        window.open('https://www.volvocars.com/us/v/cars/c40-electric?utm_source=akqa&utm_medium=referral&utm_campaign=' + id);
    }
    
    return (
        <div className={`section gallery`}>
            <div className="gallery-slides">
                <div className="gallery-slides-container">
                    <img ref={ slides[0] } className={"gallery-slide slide-1"} src={slide1} alt="The future is now" />
                    <img ref={ slides[1] } className={"gallery-slide slide-2"} src={slide2} alt="Feel the change" />
                    <img ref={ slides[2] } className={"gallery-slide slide-3"} src={slide3} alt="Google built-in" />
                </div>
            </div>
            <div className="gallery-nav">
                <img id="btnGalleryLeft" onClick={backArrowClicked} src={arrowLeft} className={"gallery-arrow arrow-left"} alt="navigate left" />
                <img id="btnGalleryRight" onClick={nextArrowClicked} src={arrowRight} className={"gallery-arrow arrow-right"}alt="navigate right" />
            </div>
            <div className={`title-block title-block-1`}>
                <h2 className="title">The future is now</h2>
                <div className="sub-title">The C40 Recharge is an expression of our desire to change perceptions — and hopefully a catalyst for more sustainable choices in personal mobility.</div>
                <button id="btnLearnMore1" onClick={() => learnMoreClicked(1)} className="learn-button">Learn More</button>
            </div>
            <div className={`title-block title-block-2`}>
                <h2 className="title">Feel the change</h2>
                <div className="sub-title">In the city or on the open highway, one-pedal drive allows for comfortable control while responsive, smooth acceleration lets the C40 Recharge deliver an exhilarating experience.</div>
                <button id="btnLearnMore2" onClick={() => learnMoreClicked(2)} className="learn-button">Learn More</button>
            </div>
            <div className={`title-block title-block-3`}>
                <h2 className="title">Google built-in</h2>
                <div className="sub-title">Google Assistant, Google Maps, and Google Play* are seamlessly integrated for a uniquely helpful and connected driving experience. Just say “Hey Google” to get started.</div>
                <button id="btnLearnMore3" onClick={() => learnMoreClicked(3)} className="learn-button">Learn More</button>
            </div>
            
        </div>
    )
}

export default Gallery;