import './App.css';
import React, { useEffect} from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import TagManager from 'react-gtm-module'
import C40 from './components/C40/C40';
import XC40 from './components/XC40/XC40';
import RotateImage from  './assets/images/rotate.png'


const tagManagerArgs = {
  gtmId: 'GTM-PMKKGQ5'
}

TagManager.initialize(tagManagerArgs)

function App() {

  useEffect(() => { 
    
  },[]);

  return (
    <Router>
       <Switch>
        <Route path="/c40/:id" children={<C40 />} />
        <Route path="/c40/" children={<C40 />} />
        <Route path="/xc40/:id" children={<XC40 />} />
        <Route path="/xc40/" children={<XC40 />} />
      </Switch>
      <div className="rotate">
          <img src={RotateImage} className="rotate-image" alt="Rotate Device" />
      </div>
    </Router>
    
  );
}

export default App;
