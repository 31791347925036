import './Steps.css';
import React, { useEffect} from 'react'

function Steps() {
    //let { id } = useParams();
    
    useEffect(() => { 

    },[]);
    

    return (
        <div className={`section steps`}>
            <div className={`step step1`}>
                <div className="step-block">
                    <h2 className="step-number">Step 01</h2>
                    <h3 className="step-title">Design</h3>
                    <div className="step-content">Select your retailer and personalize your XC40 Recharge with options, colors and styling.</div>
                </div>
            </div>
            <div className={`step step2`}>
                <div className="step-block">
                    <h2 className="step-number">Step 02</h2>
                    <h3 className="step-title">Finance</h3>
                    <div className="step-content">Review offers and find the terms that work best for you.</div>
                </div>
            </div>
            <div className={`step step3`}>
                <div className="step-block">
                    <h2 className="step-number">Step 03</h2>
                    <h3 className="step-title">Reserve</h3>
                    <div className="step-content">Review your terms and reserve your XC40 Recharge with a refundable $500 deposit.</div>
                </div>
            </div>
        </div>
    )
}

export default Steps;